import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ButtonBase, Grid } from "@material-ui/core";

import FadeInModal from "components/FadeInModal";

import { useLocation, Link, useParams } from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";

import styles from "assets/jss/chartDetailScreenstyle.js";
import NewBaseScreen from "./BaseScreen";
import { D3PieChart } from "components/new/D3PieChart";
import D3LinearChartCopy from "components/new/D3LinearChart";
import D3Bar from "components/new/D3Bar";
const useStyles = makeStyles(styles);

export default function ChartDetailScreenCopy(props) {
    const classes = useStyles();
    let { id } = useParams();
    const location = useLocation();
    const [modalState, setModalState] = React.useState({ shown: false, title: undefined });
    const downloadOptions = [{ objAttribute: 'download_excel', type: 'excel', format: 'xlsx', desc: 'MSOffice Excel', faIcon: 'fas fa-file-excel fa-2x', displayableName: 'Excel' }, { objAttribute: 'download_csv', type: 'csv', format: 'csv', desc: 'CSV', faIcon: 'fas fa-file-csv fa-2x', displayableName: 'CSV' }, { objAttribute: 'download_xml', type: 'xml', format: 'xml', faIcon: 'fas fa-file-code fa-2x', desc: 'XML (de etiquetas)', displayableName: 'XML' }];
    const [chartObject, setChartObject] = React.useState({ isLoading: true, data: [] });
    /*const openInNewTab = (url, type) => {
        axios.get(url, {
          responseType: 'blob',
          headers: {
             
          }
        }).then(res => {
          fileDownload(res.data, chartObject.data.chart.key+"_data."+type);
        }).catch(err => console.log(err));
  }*/
    //const queryParams = useQuery();




    const closeModal = () => { //function to pass through props to FadeInModal
        setModalState({ shown: false, title: modalState.title });
    };

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/chart?id=' + id).then((data) => {
            setChartObject({ isLoading: false, data: data.data });

        })
    }
        , [location, id]);

    const hasDownloadOption = () => {
        if (chartObject.isLoading)
            return false;
        var hasValidDownloadOption = false;
        downloadOptions.forEach((element) => {
            if (chartObject.data.chart[element] !== undefined)
                hasValidDownloadOption = true;
        });
        return hasValidDownloadOption;
    };

    const handleModalClick = () => {
        if (chartObject.isLoading)
            return
        setModalState({ shown: true, title: (chartObject.data.institution.name !== "Eficiencia Global" ? chartObject.data.chart.title + " del " + chartObject.data.institution.name : chartObject.data.chart.title) })
    }

    const renderDownloadOptions = () => {
        if (chartObject.isLoading)
            return false;
        return downloadOptions.map((element, index) => {
            var objectAttributeCheck = chartObject.data.chart[element.objAttribute];
            if (objectAttributeCheck !== undefined) {
                return (
                    <ButtonBase key={index} className={classes.buttonActionStyle} onClick={(event) => {
                        //window.open(ApiURLs.baseURL + 'website/download-chart?id=' + chartObject.data.chart.key + "&type=" + element.type, '_blank').focus();
                        let variant = "&variant=" + (chartObject.data.chart.variant === 2 ? "trimestral" : "anual");
                        window.open(ApiURLs.baseURL + 'website/generate-download?id=' + chartObject.data.institution.code + "&format=" + element.format + variant, '_blank').focus();
                    }}>
                        <div className={classes.dataOption}>
                            <div className={classes.dataOptionIconContainer}>
                                <i class={element.faIcon}></i>
                            </div>
                            <div className={classes.dataOptionTextHolder}>
                                <Typography variant="h4">{"Descargar " + element.displayableName}</Typography>
                                <div className={classes.dataOptionTextHolderExtra}>
                                    <Typography variant="subtitle2"> {"Descargar la información en formato " + element.desc}  </Typography>
                                </div>
                            </div>
                        </div>
                    </ButtonBase>
                )
            }
            else
                return null;
        })
    };

    return (
        <NewBaseScreen indicadoresHeader bodyColor="#f2f3f6">
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.divisoryClass}>
                    {
                        (!chartObject.isLoading) ?
                            <>
                                <Typography variant="h1" className={classes.title}><Link to={"/institution/" + chartObject.data.institution.id}>{chartObject.data.institution.name}</Link> &gt; {chartObject.data.chart.title}</Typography>
                                <div className={classes.subtitleContainer}>
                                    <Typography variant="subtitle2" className={classes.subtitle}>
                                        <div dangerouslySetInnerHTML={{ __html: (!chartObject.isLoading) ? chartObject.data.chart.description : '' }} />
                                    </Typography>
                                </div>
                            </>
                            :
                            ""
                    }
                </Grid>
                {/*<Grid item xs={12} sm={12} md={4} className={classes.divisoryClass} /> */}
                <Grid item xs={12} sm={12} md={8}>
                    <div className={classes.graphOptionsMenuContainer}>
                        <div className={classes.graphOptionsMenuContainerTitle}>
                            <Typography variant="h2" className={classes.emphasizedHeader}>{(!chartObject.isLoading) ? chartObject.data.chart.title : '...'}</Typography>
                        </div>
                        <div className={classes.graphOptionsMenuButton} onClick={handleModalClick}>
                            <div className={classes.graphOptionsMenuIcon}>
                                <i class="fas fa-info fa-1x"></i>
                            </div>
                            <Typography variant="subtitle2">Metodología</Typography>
                        </div>
                    </div>
                    <div className={classes.graphContainer}>
                        <div className={classes.graphConstraint}>
                            {
                                (!chartObject.isLoading) ?
                                    chartObject.data.chart.type === 4 ? <D3PieChart width={600} height={355} source={chartObject.data.data} requiresLegends />
                                        :
                                        chartObject.data.chart.type === 3 || chartObject.data.chart.type == 6 ? <D3Bar width={450} height={300} source={chartObject.data.data} requiresLegends type={chartObject.data.chart.type} /> :
                                            <D3LinearChartCopy width={450} height={300} source={chartObject.data.data} requiresLegends /> : ""
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.rightContentContainer}>
                    <div className={classes.dataOptions} style={{ border: hasDownloadOption() ? undefined : '0px' }}>
                        {renderDownloadOptions()}
                    </div>
                </Grid>
            </Grid>
            <FadeInModal open={modalState.shown} handleClose={closeModal} modalTitle={modalState.title}>

                <Typography variant="caption">
                    <div dangerouslySetInnerHTML={{ __html: (!chartObject.isLoading) ? chartObject.data.chart.methodology : '' }} />
                </Typography>
            </FadeInModal>
        </NewBaseScreen>
    );
}
