import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseCard from "./BaseCard.js";
import styles from "../assets/jss/baseCardstyle.js";
import { Typography } from "@material-ui/core";
import FeedItem from "./FeedItem.js";
const useStyles = makeStyles(styles);
export default function FeedsCard(props) {
    const { newsFeeds, title, buttons } = props;
    const classes = useStyles();
    return (
        //<div>
        <BaseCard hasFooter  > 
            <div className={classes.headerContainer}>
                <Typography variant="h2" className={classes.header}>{title}</Typography>
            </div>
            <div className={(props.variant!="secondrow")?classes.fluidContainer:classes.fluidContainer2}>
                {
                
                    newsFeeds.map((element,index)=>{
                        return (
                            <div key={index} className={classes.newsPost} style={{border:index==0 ? '0px' : undefined}}>
                                {/*<Typography variant="h4" className={classes.newsDescription}>{element.description}</Typography>
                                <Typography variant="subtitle2" className={classes.newsFooter}>{(element.writter? element.writter+' ' : '' )+element.date}</Typography>*/}                                
                                <FeedItem image={element.image} content={element.content} title={element.title} classes={classes.FeedItem} variant={props.variant} dataObject={element} />

                            </div>
                        )
                    })
                }
            </div>
        </BaseCard>
        // Start of FeedsCard's custom footer buttons
        //     <div className={classes.footerButtons}>
        //         {buttons.map((element,index)=>{
        //             return (
        //                 <div key={index} className={classes.footerButton} style={{borderLeft:index>0?'0px':undefined, flex:buttons.length ==1?'1':'0.5'}}>
        //                     {element}
        //                 </div>
        //             )
        //         })}
        //     </div>
        // </div>
    );
}
