import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "assets/jss/primaryScreenstyle.js";
import NewBaseScreen from "./BaseScreen";
/* import axios from "axios";
import ApiURLs from "../config/ApiURLs.js"; */
const useStyles = makeStyles(styles);

export default function TitleAndBodyScreenCopy(props) {
    //const rootRef = React.useRef(null);
    const classes = useStyles();

    /* useEffect(()=>{
        axios.get(ApiURLs.baseURL + 'website/page?id=' + location.pathname ).then((data) => {  
            setPageObject({isLoading:false,data:data.data});
        })
    }
    ,[location]); */

    return (
        <NewBaseScreen indicadoresHeader bodyColor="#f2f3f6">
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>
                    <div>
                        <Typography variant="h1" className={classes.title}>
                            Métodología de indicadores
                        </Typography>
                    </div>
                </Grid>
            </Grid>

            {/* {(pageObject.isLoading)? 
                <div>
                    ...
                </div>:
                <div>
                    <Typography variant="h1" className={classes.title}>{pageObject.data.title}</Typography>
                    <div className={classes.subtitleContainer}>
                        <Typography variant="body1" >
                            
                            <div dangerouslySetInnerHTML={ {__html: pageObject.data.content} } />
                        </Typography>
                    </div>
                </div>
            } */}
        </NewBaseScreen>
    )
}
