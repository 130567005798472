import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/basestyle";
import PrimaryHeader from "../components/PrimaryHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import SearchHeader from "../components/SearchHeader";
//import BreadCrumbs from "../components/BreadCrumbs";
import Footer from "../components/Footer";
const useStyles = makeStyles(styles);
export default function BaseScreen(props) {
    const { /*navBars, breadCrumbsRoutes,*/ bodyColor, primaryHeader, secondaryHeader, searchHeader } = props;
    const classes = useStyles();

    /*function handleClick(event) {
        event.preventDefault();
    }*/
    return (
        <div style={{ backgroundColor: bodyColor ? bodyColor : '#FFF'}} >
            {primaryHeader ? <PrimaryHeader /> : null}
            {secondaryHeader ? <SecondaryHeader /> : null}
            {searchHeader ? <SearchHeader /> : null}
            <div className={classes.wrapper} >
                {/* <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" onClick={handleClick}>
                        <Typography variant="h3">Inicio</Typography>
                    </Link>
                    <Link color="inherit" href="/instituciones" onClick={handleClick}>
                        <Typography variant="h3">Instituciones</Typography>
                    </Link>
                    <Typography variant="h3">Organismo Judicial</Typography>
                </Breadcrumbs> */}
                {/*breadCrumbsRoutes ? <BreadCrumbs routes={breadCrumbsRoutes}/> : null */}
                {props.children}
                <Footer/>
            </div>
        </div>
    )
}
