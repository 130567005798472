import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Link } from "@material-ui/core";

//import AppContext from '../state/AppContext';
import YoutubeEmbed from "components/YoutubeEmbed";
import MultipleHeaderMenu from "components/new/MultipleHeaderMenu.jsx";
import institutionsOptions from "config/institutionsOptions.js";
import GraphCardCopy from "components/new/GraphCard.jsx";
import BaseScreenCopy from "./BaseScreen.jsx";

import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";

import styles from "assets/jss/institutionScreenstyle.js";
import clsx from "clsx";
import CasosIngresados from "views/new/dashboardsQuemados/MP/CasosIngresados.jsx";
import Salidas from "views/new/dashboardsQuemados/MP/Salidas.jsx";
import Presupuesto from "./dashboardsQuemados/OJ/Presupuesto.jsx";
import PrisionPreventiva from "./dashboardsQuemados/SP/PrisionPreventiva.jsx";
import PresupuestoOJ from "./dashboardsQuemados/OJ/Presupuesto.jsx";
import PresupuestoSP from "./dashboardsQuemados/SP/Presupuesto.jsx";
import DEICDICRI from "./dashboardsQuemados/II/DEIC-DICRI.jsx";
import INACIFMP from "./dashboardsQuemados/II/INACIF-MP.jsx";
import PPTOII from "./dashboardsQuemados/II/PPTO.jsx";

const useStyles = makeStyles(styles);

export default function InstitutionScreenCopy(props) {
    var threshold = 12;
    const classes = useStyles();
    const location = useLocation();
    //const queryParams = useQuery();
    let { id, type } = useParams();//queryParams.get('id');

    const [typeP, setTypeP] = React.useState('');
    //const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] /*remove fakeData once backend is ready*/ });
    const [thisInstitution, setThisInstitution] = React.useState({ id: 1, type: 1, isLoading: true, data: { 'name': 'No hay dashboard disponible para esta institución, redirigiendo a la página de bienvenida...' } /*remove fakeData once backend is ready*/ });
    const [extraDisplayed, setExtraDisplayed] = React.useState({ displayed: false });

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/institution-dashboard?id=' + id).then((data) => {  // eslint-disable-next-line
            threshold = (data.data.code === "IDPP") ? 6 : 12;
            setThisInstitution({ data: data.data });
            setTypeP(data.data.charts.length === 1 ? data.data.charts[0].key : '');
        }).finally(() => {
            setThisInstitution((prevVal) => ({ ...prevVal, isLoading: false, id: id, type: type }))
        });
    }, [location, id, type]);

    switch (id) {
        case '1':
            return (
                type === 'DBOARD-OJ004' || type === undefined ? <PresupuestoOJ /> :
                    <></>
            );
        case '2':
            return (
                type === 'DBOARD-MP001' || type === undefined ? <CasosIngresados /> :
                    (type === 'DBOARD-MP002' ? <Salidas /> : <></>)
            );
        case '5':
            return (
                type === 'DBOARD-SP001' || type === undefined ? <PrisionPreventiva /> :
                    (type === 'DBOARD-SP004' ? <PresupuestoSP /> : <></>)
            );
        case '8':
            return (
                type === 'DBOARD-II001' ? <DEICDICRI /> :
                    (type === 'DBOARD-II002' ? <INACIFMP /> :
                        (type === 'DBOARD-II003' || type === undefined ? <PPTOII /> : <></>))
            )
        default:
            break;
    }

    setTimeout(() => {
        window.location.href = '/indicadores';
    }, 500);

    return (
        //, { display: "Instituciones", href: '/institutions' }, { display: dataRep.data.institutionName, href: '/institution?id=' + dataRep.data.institutionId 
        <BaseScreenCopy primaryHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{ display: "Inicio", href: "/index" }]}>
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>
                    <div>
                        <Typography variant="h1" className={classes.title} >
                            {(!thisInstitution.isLoading) ? thisInstitution.data.name : <i class="fas fa-spinner"></i> /*id */}
                        </Typography>
                        <div className={classes.subtitleContainer}>
                            <Typography variant="body1" className={classes.longDescription}>
                                {(!thisInstitution.isLoading) ? thisInstitution.data.description : <i class="fas fa-spinner"></i>}
                            </Typography>
                        </div>
                        {
                            (!thisInstitution.isLoading) ?
                                institutionsOptions[id] !== undefined ?
                                    <div className={classes.typeParent}>
                                        <Typography variant="h1" className={classes.typeContainer}>
                                            <MultipleHeaderMenu
                                                options={thisInstitution.data.institutionData}
                                                rootComponent={
                                                    <Link onClick={(event) => event.preventDefault()} to='#' style={{ cursor: 'pointer' }}>
                                                        <div className={clsx(classes.navLink, classes.mobileHidden)}>
                                                            {type !== undefined ? thisInstitution.data.institutionData.find((option) => option.id === type).name : thisInstitution.data.institutionData[0].name}&nbsp;
                                                            <i className='fa fa-caret-down ' />
                                                        </div>
                                                    </Link>
                                                }
                                                prefix={"/institution/" + id + '/'}
                                                close={() => { }}
                                                margin={true}
                                                children={true}
                                            />
                                        </Typography>
                                    </div>
                                    : <></>
                                : <i class="fas fa-spinner" />
                        }
                    </div>
                </Grid>
            </Grid>
        </BaseScreenCopy>
    )
}
