const url = window.location.host;
let to = '';
let cookie = '';

if (url === 'beta.justiciacriminal.gt') {
	to = 'api-beta.justiciacriminal.gt';
	cookie = 'DAJCBETA';
} else if (url === 'dev.justiciacriminal.gt') {
	to = 'test.justiciacriminal.gt';
	cookie = 'DAJCDEV';
} else if (url === 'justiciacriminal.gt') {
	to = 'app.justiciacriminal.gt';
	cookie = 'DAJC';
} else {
	to = 'flujograma.app.local';
	cookie = 'DAJCLOCAL';
}

const ApiURLs = {
	//baseURL: 'https://app.justiciacriminal.gt/v1/',
	baseURL: `https://${to}/v1/`,
	cookie: cookie
};

export default ApiURLs;
