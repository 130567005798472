import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import FlujogramaBaseScreen from "./FlujogramaBaseScreen.js";
import { Typography } from "@material-ui/core";
import styles from "../assets/jss/flujogramaScreenstyle.js";
import { useLocation, useParams } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Select from '@material-ui/core/Select';
import BaseScreen from "./BaseScreen.js";
//import FeedItem from "../components/FeedItem.js";
import YoutubeEmbed from "../components/YoutubeEmbed";
//import imageFlujograma from "../assets/img/history/FLUJOGRAMA2020.svg";

const useStyles = makeStyles(styles);

export default function FlujogramaScreenTrimester(props) {
    let { trimester,year } = useParams();
    const location = useLocation();
    const classes = useStyles();
    let imageFlujograma = "/history/FLUJOGRAMA" + year.toString() + "T"+trimester.toString()+".svg";
    const fullScreenHandle = useFullScreenHandle();
    useEffect(() => {

    }
        , [location]);
    const handleChange = (e) => {
        let valueX = e.target.value.split('T');
        props.history.push("/flujograma-trimestre/"+valueX[0]+"/"+valueX[1]);
    };
    if ((year != 2021 && year !=2022)  || (trimester < 1 || trimester > 4)) props.history.push("/notfound");
    return (
        <BaseScreen primaryHeader bodyColor="#f2f3f6">
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.divisoryClass}>
                    <Typography variant="h1" className={classes.title}>
                        Flujograma trimestral 
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <div className={classes.graphOptionsMenuContainer}>
                        <div className={classes.graphOptionsMenuContainerTitle}>
                            <Typography variant="h2" className={classes.emphasizedHeader}>Trimestre</Typography>
                            <Select
                                native
                                value={year+"T"+trimester}
                                onChange={handleChange}

                                inputProps={{
                                    name: 'anio',
                                    id: 'outlined-year-native-simple',
                                }}
                                disableUnderline
                                style={{ paddingBottom: '5px', paddingLeft: '10px' }}
                                id="fltrimester"
                            >
                                <option value={"2021T1"}>2021 T1</option>
                                <option value={"2021T2"}>2021 T2</option>
                                <option value={"2021T3"}>2021 T3</option>
                                <option value={"2021T4"}>2021 T4</option>
                                <option value={"2022T1"}>2022 T1</option>
                                <option value={"2022T2"}>2022 T2</option>
                                <option value={"2022T3"}>2022 T3</option>
                                <option value={"2022T4"}>2022 T4</option>
                            </Select>
                        </div>
                        <div className={classes.graphOptionsMenuButton} onClick={fullScreenHandle.enter}>
                            <div className={classes.graphOptionsMenuIcon}>
                                <i class="fas fa-expand"></i>
                            </div>
                            <Typography variant="subtitle2">Expandir</Typography>
                        </div>
                    </div>
                    <div className={classes.flujogramaImageContainer}>
                        <FullScreen handle={fullScreenHandle}>
                            <img src={imageFlujograma} className={classes.headerLogo} alt='Flujograma de la Justicia Criminal' />
                        </FullScreen>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className={classes.rightContentContainer}>
                    <div className={classes.rightSideTextContainer}>

                        <YoutubeEmbed embedId="pip5vlZcAoU" />
                    </div>
                </Grid>
            </Grid>

        </BaseScreen>
    )
}
