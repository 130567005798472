import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Link } from "@material-ui/core";

//import AppContext from '../state/AppContext';
import MultipleHeaderMenu from "components/new/MultipleHeaderMenu.jsx";
import institutionsOptions from "config/institutionsOptions.js";
import GraphCardCopy from "components/new/GraphCard.jsx";
import BaseScreenCopy from "views/new/BaseScreen";

import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";

import styles from "assets/jss/institutionScreenstyle.js";
import clsx from "clsx";
import DashboardDescription from "components/new/DashboardDescription";
import GeneralDescription from "components/new/GeneralDescription";

const useStyles = makeStyles(styles);

const textos = [
    { description: 'En los últimos años el MP le dio salida a <b>más de medio millón</b> de casos por año..' },
    { description: 'De los <b>804,072</b> casos a los que el MP les dio salida en el año 2022, el <b>23%</b> (187,415) fueron casos archivados.' },
    { description: 'En los últimos años se observa una tendencia creciente en la tasa de atención de casos.' },
]

export default function Salidas(props) {
    var threshold = 12;
    const classes = useStyles();
    const location = useLocation();
    //const queryParams = useQuery();
    let { id, type } = useParams();//queryParams.get('id');
    //const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] /*remove fakeData once backend is ready*/ });
    const [thisInstitution, setThisInstitution] = React.useState({ id: 1, type: 1, isLoading: true, data: { 'name': '...' } /*remove fakeData once backend is ready*/ });
    const [extraDisplayed, setExtraDisplayed] = React.useState({ displayed: false });

    React.useEffect(() => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo') ? sessionStorage.getItem('userInfo') : '{}');
        const url = `${ApiURLs.baseURL}website/institution-dashboard?id=${id}${type === null || type === undefined ? '' : '&type=' + type}`
        axios.get(url).then((data) => {  // eslint-disable-next-line
            threshold = (data.data.code === "IDPP") ? 6 : 12;
            setThisInstitution({ data: data.data });
        }).finally(() => {
            setThisInstitution((prevVal) => ({ ...prevVal, isLoading: false, id: id, type: type }))
        });
    }, [location, id, type]);

    return (
        <BaseScreenCopy indicadoresHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{ display: "Inicio", href: "/index" }]}>
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>
                    <div>
                        <Typography variant="h1" className={classes.title} >
                            {(!thisInstitution.isLoading) ? thisInstitution.data.name : <i class="fas fa-spinner"></i> /*id */}
                        </Typography>
                        <div className={classes.subtitleContainer}>
                            <Typography variant="body1" className={classes.longDescription}>
                                {(!thisInstitution.isLoading) ? thisInstitution.data.description : <i class="fas fa-spinner"></i>}
                            </Typography>
                        </div>
                        {
                            (!thisInstitution.isLoading) ?
                                thisInstitution.data.institutionData.length > 0 ?
                                    <div className={classes.typeParent}>
                                        <Typography variant="h1" className={classes.typeContainer}>
                                            <MultipleHeaderMenu
                                                options={thisInstitution.data.institutionData}
                                                rootComponent={
                                                    <Link onClick={(event) => event.preventDefault()} to='#' style={{ cursor: 'pointer' }}>
                                                        <div className={clsx(classes.navLink, classes.mobileHidden)}>
                                                            {type !== undefined ? thisInstitution.data.institutionData.find((option) => option.id === type).name : thisInstitution.data.institutionData[0].name}&nbsp;
                                                            <i className='fa fa-caret-down ' />
                                                        </div>
                                                    </Link>
                                                }
                                                prefix={"/institution/" + id + '/'}
                                                close={() => { }}
                                                margin={true}
                                                children={true}
                                                selected={type}
                                            />
                                        </Typography>
                                    </div>
                                    : <></>
                                : <i class="fas fa-spinner" />
                        }
                    </div>
                </Grid>

                <GeneralDescription type={'DBOARD-MP002'} />

                <Grid container xs={12} sm={12} md={12} style={{ marginTop: '2rem' }}>
                    {
                        (!thisInstitution.isLoading) ? thisInstitution.data.charts.map((element, index) => {
                            threshold = (thisInstitution.data.code === "IDPP") ? 6 : 12;
                            if (index < threshold) {
                                if (element.data === null || element.data === undefined) {
                                    return <></>;
                                }

                                if (index === (thisInstitution.data.charts.length - 1))
                                    return (
                                        <Grid container xs={12} sm={12} md={12} key={`${index}`}>
                                            <Grid item xs={3} sm={3} md={3} lg={3} key={`graph-${index}`} />
                                            <Grid item xs={6} sm={12} md={6} lg={6} key={`${index}`}>
                                                <Grid item xs={12} sm={12} md={12} key={`graph-${index}`} /* onMouseEnter={() => console.log('test')} */>
                                                    <GraphCardCopy graphData={element} justDetailButton institution={thisInstitution.data} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} key={`text-${index}`}>
                                                    <DashboardDescription description={textos[index].description} bottom={textos[index].bottom || null} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                else
                                    return (
                                        <Grid item xs={12} sm={12} md={6} key={`${index}`}>
                                            <Grid item xs={12} sm={12} md={12} key={`graph-${index}`} /* onMouseEnter={() => console.log('test')} */>
                                                <GraphCardCopy graphData={element} justDetailButton institution={thisInstitution.data} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} key={`text-${index}`}>
                                                <DashboardDescription description={textos[index].description} bottom={textos[index].bottom || null} />
                                            </Grid>
                                        </Grid>
                                    )

                            } else
                                return "";
                        }) : "No hay datos que mostrar aún."
                    }
                    {
                        ((!thisInstitution.isLoading) && (thisInstitution.data.charts.length > threshold)) ? <div className={classes.divExtraButtonCont}><Button variant="outlined" onClick={() => { setExtraDisplayed({ displayed: (!extraDisplayed.displayed) }) }}> {(!extraDisplayed.displayed) ? "Más detalles" : "Ocultar detalle"}</Button></div> : ""
                    }
                    {
                        (!thisInstitution.isLoading) ?
                            <Grid container xs={12} sm={12} md={12} id="extraGrid" className={(extraDisplayed.displayed) ? classes.activeExtraGrid : classes.hiddenExtraGrid}>

                                {
                                    thisInstitution.data.charts.map((element, index) => {
                                        if (index >= threshold)
                                            return (
                                                <Grid item xs={12} sm={12} md={6} key={index}>
                                                    <GraphCardCopy graphData={element} justDetailButton institution={thisInstitution.data} />
                                                </Grid>
                                            );
                                        else
                                            return "";
                                    })
                                }
                            </Grid>
                            : ""
                    }
                </Grid >
            </Grid>
        </BaseScreenCopy>
    )
}
