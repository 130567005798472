import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/baseCardstyle";
const useStyles = makeStyles(styles);

export default function BaseCardCopy(props) {
    const classes = useStyles();
    const { bodyColor, height, hasFooter } = props;
    return (
        <div className={hasFooter ? classes.containerFooterCase : classes.containerFooterCase} style={{ backgroundColor: bodyColor ? bodyColor : '#FFF', height: height ? height : undefined }} >
            {props.children}
        </div>
    )
}
