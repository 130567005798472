import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import FeedsCard from "components/FeedsCard";
import SlideshowCard from "components/SlideshowCard";
import D3LinearChart from "components/D3LinearChart";
import BaseCard from "components/BaseCard";
import YoutubeEmbed from "components/YoutubeEmbed";
import LoadingIndicator from "components/LoadingIndicator.js";

import styles from "assets/jss/primaryScreenstyle.js";

import axios from 'axios';
import ApiURLs from "config/ApiURLs.js";
import { Link } from "react-router-dom";
import NewBaseScreen from "./BaseScreen.jsx";
import FeedsCardCopy from "components/new/FeedsCard.jsx";

const useStyles = makeStyles(styles);

export default function PrimaryScreenIndicadores(props) {
    //const appContext = React.useContext(AppContext);
    //const rootRef = React.useRef(null);
    let baseYear = (new Date().getFullYear()) - 1;
    const classes = useStyles();
    //const [searchValue, setSearchValue] = React.useState('');

    const [videoList, setVideoList] = React.useState({ isLoading: true, data: { 'name': '...' } /*remove fakeData once backend is ready*/ });
    const [mainChart, setMainChart] = React.useState({ isLoading: true, data: {} });

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/video-list?id=vl001').then((data) => {
            setVideoList({ isLoading: false, data: data.data });
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=C-GLOBAL-004').then((data) => {
            setMainChart({ isLoading: false, data: data.data });
        });
    }, []);

    /* const handleChange = (newValue) => {
        setSearchValue(newValue);
    }; */
    return (
        <NewBaseScreen indicadoresHeader bodyColor="#f2f3f6">
            <div>
                <Typography variant="h1" className={classes.title}>Indicadores de la Justicia Criminal - Guatemala</Typography>
                <div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Hacia un sistema de justicia transparente, eficiente y digital.</Typography>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} sm={4} md={4}>
                    {
                        videoList.isLoading ? <LoadingIndicator /> : <FeedsCardCopy newsFeeds={videoList.data.videos} title={'INFORMACIÓN RECIENTE'} buttons={[]} variant='videolist' />
                    }
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    {
                        videoList.isLoading ? <LoadingIndicator /> :
                            <BaseCard height="97%">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography className={classes.contenidoPendiente}>
                                        Carrusel de gráficas a definir por la estrategia de comunicación
                                    </Typography>
                                </div>
                                {/* <YoutubeEmbed embedId="nBXq7vNn-VM" /> */}
                            </BaseCard>
                    }
                    {/* {
                        mainChart.isLoading ? <LoadingIndicator /> : <SlideshowCard slideElements={[{ main: <D3LinearChart width={700} height={240} source={mainChart.data.data} isPreview />, title: 'Eficiencia Global del Sistema', subtitle: 'La eficiencia global del sistema a través de los años', chartId: 'C-GLOBAL-004' }]} />
                    } */}
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                    <MediaCard title={'Lanzamiento de la plataforma'} subtitle={'05 octubre 2021'} image={invitationImage} />
                </Grid> */}

                <Grid item xs={12} sm={6} md={6}>
                    {
                        videoList.isLoading ? <LoadingIndicator /> :
                            <BaseCard height="166px">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography className={classes.contenidoPendiente}>
                                        Video a insertar
                                    </Typography>
                                </div>
                                {/* <YoutubeEmbed embedId="nBXq7vNn-VM" /> */}
                            </BaseCard>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {
                        videoList.isLoading ? <LoadingIndicator /> :
                            <BaseCard height="166px">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography className={classes.contenidoPendiente}>
                                        Contenido a definir por la estrategia de comunicación
                                    </Typography>
                                </div>
                                {/* <Link to={"/flujograma/" + baseYear}>
                            <img src='/history/animated.gif' style={{ width: "100%", height: "100%" }} alt="Flujograma de la Justicia Criminal" />
                        </Link> */}
                            </BaseCard>
                    }
                </Grid>
            </Grid>
        </NewBaseScreen>
    )
}
