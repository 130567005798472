import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import FeedsCard from "components/FeedsCard";
import SlideshowCard from "components/SlideshowCard";
import D3LinearChart from "components/D3LinearChart";
import BaseCard from "components/BaseCard";
import YoutubeEmbed from "components/YoutubeEmbed";
import LoadingIndicator from "components/LoadingIndicator.js";

import styles from "assets/jss/primaryScreenstyle.js";

import axios from 'axios';
import ApiURLs from "config/ApiURLs.js";
import { Link } from "react-router-dom";
import NewBaseScreen from "./BaseScreen.jsx";
import D3Bar from "components/new/D3Bar.jsx";
import SlideshowCardNew from "components/new/SlideshowCard.jsx";
import D3LinearChartCopy from "components/new/D3LinearChart.jsx";

const useStyles = makeStyles(styles);

export default function PrimaryScreenCopy(props) {
    //const appContext = React.useContext(AppContext);
    //const rootRef = React.useRef(null);
    let baseYear = (new Date().getFullYear()) - 1;
    const classes = useStyles();
    //const [searchValue, setSearchValue] = React.useState('');

    const [videoList, setVideoList] = React.useState({ isLoading: true, data: { 'name': '...' } /*remove fakeData once backend is ready*/ });
    const [mainChart, setMainChart] = React.useState({ isLoading: true, data: {} });
    const [chart1, setChart1] = React.useState({ isLoading: true, data: {} });
    const [chart2, setChart2] = React.useState({ isLoading: true, data: {} });

    React.useEffect(() => {
        axios.get(ApiURLs.baseURL + 'website/video-list?id=vl001').then((data) => {
            setVideoList({ isLoading: false, data: data.data });
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=C-GLOBAL-004').then((data) => {
            setMainChart({ isLoading: false, data: data.data });
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=II-BAR-003').then((data) => {
            setChart1({ isLoading: false, data: data.data });
        });

        axios.get(ApiURLs.baseURL + 'website/chart?id=SP-BAR-001').then((data) => {
            setChart2({ isLoading: false, data: data.data });
        });
    }, []);

    /* const handleChange = (newValue) => {
        setSearchValue(newValue);
    }; */
    return (
        <NewBaseScreen primaryHeader bodyColor="#f2f3f6">
            <div>
                <Typography variant="h1" className={classes.title}>Datos de libre acceso de la Justicia Criminal - Guatemala</Typography>
                <div className={classes.subtitleContainer}>
                    <Typography variant="h2" className={classes.subtitle}>Hacia un sistema de justicia transparente, eficiente y digital.</Typography>
                </div>
            </div>
            <Grid container>
                <Grid item xs={12} sm={5} md={5}>
                    {/* 
                        videoList.isLoading ? <LoadingIndicator /> : <FeedsCard newsFeeds={videoList.data.videos} title={'VIDEOS'} buttons={[]} variant='videolist' />
                    */}
                    {
                        videoList.isLoading ? <LoadingIndicator /> :
                            <BaseCard height="97%">
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography className={classes.contenidoPendiente}>
                                        Video a insertar
                                    </Typography>
                                </div>
                                {/* <YoutubeEmbed embedId="nBXq7vNn-VM" /> */}
                            </BaseCard>
                    }
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                    {
                        chart1.isLoading ? <LoadingIndicator /> : <SlideshowCardNew slideElements={[{ main: <D3Bar width={700} height={255} source={chart1.data.data} requiresLegends2 type={3}  />, title: 'Distribución del presupuesto de gastos del sector justicia', subtitle: 'Años 2014-2022', chartId: 'II-BAR-003' }]} />
                    }
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    {
                        mainChart.isLoading ? <LoadingIndicator /> : <SlideshowCard slideElements={[{ main: <D3LinearChartCopy width={700} height={335} source={mainChart.data.data} isPreview />, title: 'Eficiencia Global del Sistema', subtitle: 'La eficiencia global del sistema a través de los años', chartId: 'C-GLOBAL-004' }]} />
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {
                        chart2.isLoading ? <LoadingIndicator /> : <SlideshowCardNew slideElements={[{ main: <D3Bar width={700} height={300} source={chart2.data.data} requiresLegends2 type={3}  />, title: 'Privados de libertad por situación jurídica', subtitle: 'Años 2014-2022', chartId: 'SP-BAR-001' }]} />
                    }
                </Grid>
            </Grid>
        </NewBaseScreen>
    )
}
