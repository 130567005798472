import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PrimaryScreen from './views/PrimaryScreen';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ChartDetailScreen from './views/ChartDetailScreen';
import { AppProvider } from './state/AppContext';
import InstitutionScreen from './views/InstitutionScreen';
import InstitutionsScreen from './views/InstitutionsScreen';
import GlobalScreen from './views/GlobalScreen';
import TitleAndBodyScreen from './views/TitleAndBodyScreen';
import FlujogramaScreen from './views/FlujogramaScreen';
import FlujogramaScreenTrimester from './views/FlujogramaScreenTrimester';
import PrimaryScreenCopy from 'views/new/PrimaryScreen';
import InstitutionScreenCopy from 'views/new/InstitutionScreen';
import ChartDetailScreenCopy from 'views/new/ChartDetailScreen';
import TitleAndBodyScreenCopy from 'views/new/TitleAndBodyScreen';
import PrimaryScreenIndicadores from 'views/new/PrimaryScreenIndicadores';

const hist = createBrowserHistory();
hist.listen((location, action) => {
	window.scrollTo(0, 0);
	//console.log("Desde history.....");
});
const GlobalFontFamily = "'Avenir Next LT Pro Regular','Open Sans', 'sans-serif'";
const commonValues = {
	fontSize: '1.6rem',
	fontWeight: 600,
	lineHeight: '140%',
	fontFamily: GlobalFontFamily,
	'@media (max-width: 600px)': {
		fontSize: '1.5rem'
	},
	color: "#003a6f",
};

const customTheme = createMuiTheme({
	palette: {
		primary: {
			//main: '#1f95dd' //lightblue - celeste
			main: '#003b71' //lightblue - celeste #6389a1
		},
		secondary: {
			lighter: '#e4e9ec',////Even lighter, generally used for margins in cards (refer to BaseCard)
			light: '#81878C',//lighter gray
			main: '#50595E',//gray - gris
			dark: '#003b71', //'#101f28', //almost black
		},
		hover: {
			backgroundColor: '#f8f9fa'
		}
	},
	typography: {
		h1: {
			fontSize: '2.4rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: GlobalFontFamily,///because we are setting the default fontFamily on index.css
			'@media (max-width: 600px)': {
				fontSize: '2.4rem'
			},
			color: "#003a6f",
		},
		h2: {
			fontSize: '1.8rem',
			fontWeight: 600,
			lineHeight: '140%',
			fontFamily: GlobalFontFamily,///because we are setting the default fontFamily on index.css
			'@media (max-width: 600px)': {
				fontSize: '1.6rem'
			},
			color: "#003a6f",
		},
		h3: commonValues,
		h4: commonValues,
		h5: commonValues,
		caption: {
			fontSize: '1.4rem',
			fontFamily: GlobalFontFamily,
			letterSpacing: "0.01111em"
		},
		subtitle2: { ///for elements such as the newsFooter on FeedsCard. Generally they are the smallest subtitles and are represented by an span tag
			fontSize: '1.3rem',
			fontFamily: GlobalFontFamily,///because we are setting the default fontFamily on index.css
		},
		body1: {
			fontSize: "1.5rem",
			fontFamily: GlobalFontFamily,
		}
	},
	customConstants: {
		pageBackgroundColor: "#f2f3f6"
	}
});


ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={customTheme}>
			<AppProvider>
				<Router history={hist}>
					<Switch>
						{/* <Route path="/bienvenido" component={PrimaryScreen} />
						<Route path="/viewChart/:id" component={ChartDetailScreen} />
						<Route path="/global" component={GlobalScreen}  exact/>
						<Route path="/institutions" component={InstitutionsScreen}  exact/>
						<Route path="/institution/:id" component={InstitutionScreen} />
						<Route path="/flujograma-trimestre/:year/:trimester" component={FlujogramaScreenTrimester} />
						<Route path="/flujograma/:year" component={FlujogramaScreen} />
						<Route path="/terms-and-conditions" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} />
						<Route path="/faq" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} />
						<Route path="/troubleshooting" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} />
						<Route path="/methodology" component={TitleAndBodyScreen} exact key={()=>{Math.random()}} /> */}

						<Route path="/methodology" component={TitleAndBodyScreenCopy} exact key={() => { Math.random() }} />
						<Route path="/viewChart/:id" component={ChartDetailScreenCopy} />
						<Route path="/institution/:id/:type" component={InstitutionScreenCopy} />
						<Route path="/institution/:id" component={InstitutionScreenCopy} />
						<Route path="/bienvenido" component={PrimaryScreenCopy} />
						<Route path="/indicadores" component={PrimaryScreenIndicadores} />

						<Redirect to="/bienvenido" />
					</Switch>
				</Router>
			</AppProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
