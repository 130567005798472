import React from "react";

import D3BarChart from "./D3BarChart";
import * as d3 from "d3";

export default function D3Bar(props) {
    const { source, isPreview, width, height, requiresPercentage, requiresLegends, requiresLegends2, type } = props;
    const [dataRep, setDataRep] = React.useState({ isLoading: true, data: source, scaledBy: undefined });
    var scaleOptions = [{ base: 1000, label: 'Mil', xOffset: 10 }, { base: 1000000, label: 'Mill', xOffset: 8 }]; //always 

    const scaleData = (unScaledData) => {
        var yMeanValue = series.map(serie => serie.reduce((prev, current) => { return [0, prev[1] + Math.abs(current[1])] }, [0, 0])[1] / unScaledData.length);
        yMeanValue = yMeanValue.reduce((prev, current) => { return prev + Math.abs(current) }, 0) / yMeanValue.length;

        for (var i = scaleOptions.length - 1; i >= 0; i--) {
            var currentScale = scaleOptions[i];
            var scaleFound = undefined;
            if (yMeanValue > currentScale.base) {
                scaleFound = currentScale;
                break;
            }
        }
        var scaledArray = unScaledData;
        if (scaleFound) {
            if (!unScaledData.hasOwnProperty('unscaledData')) {
                scaledArray['unscaledData'] = JSON.stringify(unScaledData);
                scaledArray['scaledBy'] = JSON.stringify(currentScale);
            }
            scaledArray = scaledArray.map((element) => {
                var elementToFix = element;
                groupedDataKeys.map(key => elementToFix[key] = +(elementToFix[key] / scaleFound.base).toFixed(1));
                return elementToFix;
            });
        }
        return { data: scaledArray, scaledBy: (scaledArray.hasOwnProperty('scaledBy') ? JSON.parse(scaledArray['scaledBy']) : scaleFound) };
    }

    var groupedDataKeys = Object.keys(dataRep.data[0]).filter(key => key !== 'x' && !key.includes('type') && !key.includes('PER>') && !key.includes('CURR>') && !key.includes('TOTAL>') && key !== "unscaledData");
    var percentageKeys = Object.keys(dataRep.data[0]).filter(key => key.includes('PER>'));
    var typeVal = dataRep.data[0]?.type;

    let percentageValues = percentageKeys.reduce((acc, key) => {
        acc[key] = dataRep.data.map(t => t[key]);
        return acc;
    }, {});

    var currencyKeys = Object.keys(dataRep.data[0]).filter(key => key.includes('CURR>'));
    let currency = currencyKeys.slice(0, 1).map((key) => {
        return dataRep.data.slice(0, 1).map(t => t[key])[0];
    }, []);

    /* let currencyValues = {};
    currencyKeys.map(key => { currencyValues[key] = dataRep.data.map(t => t[key]); return key }) */

    const allGroups = dataRep.data.map((d) => String(d.x));

    let series = null;
    if (type === 3) {
        const stackSeries = d3.stack().keys(groupedDataKeys).order(d3.stackOrderNone);
        //.offset(d3.stackOffsetNone);
        series = stackSeries(dataRep.data);
    } else {
        series = groupedDataKeys.map((key, index) => {
            const newItem = dataRep.data.map(item => {
                let newVal = [0, item[key]];
                newVal.data = item;
                return newVal;
            });
            newItem.key = key;
            newItem.index = index;
            return newItem;
        });
    }

    React.useEffect(() => {
        if (undefined !== source) {
            if (source.hasOwnProperty('scaledBy')) {
                return;
            }
            const fullyTreatedData = scaleData(source);
            setDataRep({ isLoading: false, data: fullyTreatedData.data, scaledBy: fullyTreatedData.scaledBy });
        }  // eslint-disable-next-line
    }, []);


    let today = new Date();
    today.setDate(today.getDate() + 93 - (((today.getMonth()) % 3) * 30 + today.getDate()));

    /* const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } */

    if (source === undefined) return <></>;

    return (
        <D3BarChart
            source={source}
            isPreview={isPreview}
            width={width}
            height={height}
            requiresPercentage={requiresPercentage}
            requiresLegends={requiresLegends}
            requiresLegends2={requiresLegends2}
            groupedDataKeys={groupedDataKeys}
            percentageKeys={percentageKeys}
            allGroups={allGroups}
            series={series}
            percentageValues={percentageValues}
            currency={currency}
            today={today}
            type={type}
            typeVal={typeVal}
        />
    );
}

