import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Link } from "@material-ui/core";

//import AppContext from '../state/AppContext';
import MultipleHeaderMenu from "components/new/MultipleHeaderMenu.jsx";
import institutionsOptions from "config/institutionsOptions.js";
import GraphCardCopy from "components/new/GraphCard.jsx";
import BaseScreenCopy from "views/new/BaseScreen";

import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import ApiURLs from "config/ApiURLs.js";

import styles from "assets/jss/institutionScreenstyle.js";
import clsx from "clsx";
import DashboardDescription from "../../../../components/new/DashboardDescription";
import GeneralDescription from "../../../../components/new/GeneralDescription";

const useStyles = makeStyles(styles);

const textos = [
    { description: 'En los últimos seis años el promedio de solicitudes activas del MP en INACIF ha sido de <b>75.5%</b>, registrándose la tasa más baja en el 2021 <b>(70%)</b>.' },
    { description: 'En su mayoría las solicitudes activas en INACIF son recibidas por parte del Ministerio Público, el Organismo Judicial en los últimos seis años únicamente ha solicitado el <b>24.5%</b> de las solicitudes activas en INACIF.' },
    { description: 'En su mayoría las solicitudes activas en INACIF son recibidas por parte del Ministerio Público, el Organismo Judicial en los últimos seis años únicamente ha solicitado el <b>24.5%</b> de las solicitudes activas en INACIF.' },
]

export default function INACIFMP(props) {
    var threshold = 12;
    const classes = useStyles();
    const location = useLocation();
    //const queryParams = useQuery();
    let { id, type } = useParams();//queryParams.get('id');
    //const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] /*remove fakeData once backend is ready*/ });
    const [thisInstitution, setThisInstitution] = React.useState({ id: 1, type: 1, isLoading: true, data: { 'name': '...' } /*remove fakeData once backend is ready*/ });
    const [extraDisplayed, setExtraDisplayed] = React.useState({ displayed: false });

    React.useEffect(() => {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo') ? sessionStorage.getItem('userInfo') : '{}');
        const url = `${ApiURLs.baseURL}website/institution-dashboard?id=${id}${type === null || type === undefined ? '' : '&type=' + type}${userInfo !== null ? '&access_token=' + userInfo.access_token : ''}`
        axios.get(url).then((data) => {  // eslint-disable-next-line
            threshold = (data.data.code === "IDPP") ? 6 : 12;
            setThisInstitution({ data: data.data });
        }).finally(() => {
            setThisInstitution((prevVal) => ({ ...prevVal, isLoading: false, id: id, type: type }))
        });
    }, [location, id, type]);

    return (
        <BaseScreenCopy indicadoresHeader bodyColor="#f2f3f6" breadCrumbsRoutes={[{ display: "Inicio", href: "/index" }]}>
            <Grid container>
                <Grid container xs={12} sm={12} md={12}>
                    <div style={{ width: '100%' }}>
                        <Typography variant="h1" className={classes.title} >
                            {(!thisInstitution.isLoading) ? thisInstitution.data.name : <i class="fas fa-spinner"></i> /*id */}
                        </Typography>
                        <div className={classes.subtitleContainer}>
                            <Typography variant="body1" className={classes.longDescription}>
                                {(!thisInstitution.isLoading) ? thisInstitution.data.description : <i class="fas fa-spinner"></i>}
                            </Typography>
                        </div>
                        <center>
                            {
                                (!thisInstitution.isLoading) ?
                                    thisInstitution.data.institutionData.length > 0 ?
                                        <div className={classes.typeParent}>
                                            <Typography variant="h1" className={classes.typeContainer}>
                                                <MultipleHeaderMenu
                                                    options={thisInstitution.data.institutionData}
                                                    rootComponent={
                                                        <Link onClick={(event) => event.preventDefault()} to='#' style={{ cursor: 'pointer' }}>
                                                            <div className={clsx(classes.navLink, classes.mobileHidden)}>
                                                                {type !== undefined ? thisInstitution.data.institutionData.find((option) => option.id === type).name : thisInstitution.data.institutionData[0].name}&nbsp;
                                                                <i className='fa fa-caret-down' />
                                                            </div>
                                                        </Link>
                                                    }
                                                    prefix={"/institution/" + id + '/'}
                                                    close={() => { }}
                                                    margin={true}
                                                    children={true}
                                                    selected={type}
                                                />
                                            </Typography>
                                        </div>
                                        : <></>
                                    : <i class="fas fa-spinner" />
                            }
                        </center>
                    </div>
                </Grid>

                {(!thisInstitution.isLoading) ? <GeneralDescription type={'DBOARD-II002'} /> : <i class="fas fa-spinner"></i>}

                <Grid container xs={12} sm={12} md={12} style={{ marginTop: '2rem' }}>
                    {
                        (!thisInstitution.isLoading) ? thisInstitution.data.charts.map((element, index) => {

                            threshold = (thisInstitution.data.code === "IDPP") ? 6 : 12;
                            if (index < threshold) {
                                if (element.data === null || element.data === undefined) {
                                    return <></>;
                                }
                                return (
                                    <Grid item xs={12} sm={12} md={6} key={`${index}`}>
                                        <Grid item xs={12} sm={12} md={12} key={`graph-${index}`} /* onMouseEnter={() => console.log('test')} */>
                                            <GraphCardCopy graphData={element} justDetailButton institution={thisInstitution.data} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} key={`text-${index}`} >
                                            <DashboardDescription description={textos[index].description} bottom={textos[index].bottom || null} />
                                        </Grid>
                                    </Grid>
                                )
                            } else
                                return "";
                        }) : "No hay datos que mostrar aún."
                    }
                    {
                        ((!thisInstitution.isLoading) && (thisInstitution.data.charts.length > threshold)) ? <div className={classes.divExtraButtonCont}><Button variant="outlined" onClick={() => { setExtraDisplayed({ displayed: (!extraDisplayed.displayed) }) }}> {(!extraDisplayed.displayed) ? "Más detalles" : "Ocultar detalle"}</Button></div> : ""
                    }
                    {
                        (!thisInstitution.isLoading) ?
                            <Grid container xs={12} sm={12} md={12} id="extraGrid" className={(extraDisplayed.displayed) ? classes.activeExtraGrid : classes.hiddenExtraGrid}>

                                {
                                    thisInstitution.data.charts.map((element, index) => {
                                        //console.log(index,"#",element);
                                        // if (element.type == 1) {

                                        if (index >= threshold)
                                            return (
                                                <Grid item xs={12} sm={12} md={6} key={index}>
                                                    <GraphCardCopy graphData={element} justDetailButton institution={thisInstitution.data} />
                                                </Grid>
                                            );
                                        else
                                            return "";
                                        // }
                                    })
                                }
                            </Grid>
                            : ""
                    }
                </Grid >
            </Grid>
        </BaseScreenCopy >
    )
}
