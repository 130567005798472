import React from "react";
import { useTheme, makeStyles } from '@material-ui/core/styles';

import { Popover, Typography } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

import { Link } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppContext from "state/AppContext.js";
import styles from "assets/jss/headerstyle.js";
/*const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
*/
/*
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
*/

const useStyles = makeStyles(styles);


/*const websiteMenuLinks =
    [
        {
            "id": 1,
            "label": "Términos y Condiciones",
            "linkTo": "/terms-and-conditions"
        },
        {
            "id": 2,
            "label": "Preguntas frecuentes",
            "linkTo": "/faq"
        },
        {
            "id": 3,
            "label": "Resolución de problemas",
            "linkTo": "/troubleshooting"
        }
    ]
    ;*/

export default function HeaderMenuCopy(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const appContext = React.useContext(AppContext);
    const theme = useTheme();
    const isNonMobile = useMediaQuery(theme.breakpoints.up('sm'));
    let baseYear = (new Date().getFullYear()) - 1;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const conditionalMenu = () => {
        if (isNonMobile)
            return null; //because these options will appear as navLinks :D so theres no need to render them here
        return (
            <div className={classes.popoverPaperContentColumn}>
                <div className={classes.popoverPaperContentColumnTitle} onClick={handleClose}>
                    <Link to={'/global'}>
                        <Typography variant="caption" >
                            Eficiencia Global
                        </Typography>
                    </Link>
                </div>
                <div className={classes.popoverPaperContentColumnTitle} onClick={handleClose}>
                    <Link to={'/institutions'}>
                        <Typography variant="caption" >
                            Eficiencia por Institución
                        </Typography>
                    </Link> </div>
                <div className={classes.popoverPaperContentColumnTitle} onClick={handleClose}>
                    <Link to={'/methodology'}>
                        <Typography variant="caption" >
                            Metodología
                        </Typography>
                    </Link>
                </div>
            </div>
        )
    };

    const renderDynamicPages = () => {
        ///TODO - Backend route to return a list of all the dynamic pages (Just like the one that returns all the institutions), so in this method we would only iterate over that array instead of just displaying static data. 
        return (
            <div className={classes.popoverPaperContentColumn}>
                <div className={classes.popoverPaperContentColumnTitle}> Navegación </div>
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={'/methodology'}>
                        <Typography variant="caption" >
                            Metodología
                        </Typography>
                    </Link>
                </div>
            </div>
        )
    };

    const renderFlujogramaMenu = () => {
        return (
            <div className={classes.popoverPaperContentColumn}>
                <div className={classes.popoverPaperContentColumnTitle}> Flujograma </div>
                <div className={classes.popoverPaperContentColumnSubtitle} onClick={handleClose}>
                    <Link to={{ pathname: 'https://justiciacriminal.gt' }} target='_blank'>
                        <Typography variant="caption" >
                            Ir al flujograma
                        </Typography>
                    </Link>
                </div>
            </div >
        )
    }

    const renderInstitutions = () => {

        if (!appContext.state.isLoading) {
            return (
                <div className={classes.popoverPaperContentColumn}>
                    <div className={classes.popoverPaperContentColumnTitle}> Instituciones </div>
                    {
                        appContext.state.institutions.map((element, index) => {
                            return (
                                <div className={classes.popoverPaperContentColumnSubtitle} key={index} onClick={handleClose}>
                                    <Link to={'/institution/' + element.id} className={(element.id === 2 ? '' : classes.linkDisabled)}>
                                        <Typography variant="caption" className={(element.id === 2 ? '' : classes.linkDisabled)} onClick={(e) => handleClose(e, element)}>
                                            {element.name}
                                        </Typography>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            );
        }
    }
    if (isNonMobile)
        return null;
    else
        return (

            <div>
                <MenuIcon className={classes.navBarButton} style={{ marginRight: 'none !important' }} onClick={handleClick} />
                <Popover
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    classes={{ paper: classes.popoverPaper }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <div className={classes.popoverPaperContent}>
                        {renderFlujogramaMenu()}
                        {renderInstitutions()}
                        {renderDynamicPages()}
                    </div>
                </Popover>
            </div>
        );
}