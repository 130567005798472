import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseCard from "components/BaseCard";
import styles from "assets/jss/baseCardstyle.js";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom"
const useStyles = makeStyles(styles);

export default function SlideshowCardNew(props) {
    const { slideElements } = props;
    const classes = useStyles();
    return (
        <BaseCard >
            <Link to={"/viewChart/" + slideElements[0].chartId} style={{ textDecoration: 'none' }}>
                <div className={classes.slideshowContainer} style={{marginTop: '1.4rem', marginBottom: '-1.4rem'}}>
                    {
                        <div>
                            <div>
                                {
                                    slideElements[0].main
                                }
                            </div>
                            <div style={{marginTop: '-1.5rem'}}>
                                <Typography variant="h3" className={classes.mediaTitle}>{slideElements[0].title}</Typography>
                                <Typography variant="caption" className={classes.mediaSubtitle}>{slideElements[0].subtitle}</Typography>
                            </div>
                        </div>
                    }
                </div>
            </Link>
        </BaseCard>
    );
}