import React from 'react';
import { Typography, Menu, ListItemText, MenuItem, Divider } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

import styles from "assets/jss/headerstyle.js";

const useStyles = makeStyles(styles);
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => {
    return (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            disableScrollLock={true}
            anchorOrigin={{
                vertical: props.type ? 'top' : 'bottom',
                horizontal: props.type ? 'right' : 'center',
            }}
            transformOrigin={{
                vertical: props.type ? 'top' : 'top',
                horizontal: props.type ? 'left' : 'center',
            }}
            {...props}
        />
    )
});

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function MultipleHeaderMenu(props) { //will be used to display institutions, and the main difference between this and HeaderMenu.js is the with it occupies
    const classes = useStyles();
    const { close, rootComponent, options, prefix, margin, children, selected } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e, val, children) => {
        if (val.id === 1 || val.id === 2 || val.id === 5 || val.id === 7) {
            setAnchorEl(null);
            close();
        } else {
            setAnchorEl(null);
            if (children)
                close();
            else
                e.preventDefault()
        }
    };

    const renderOptions = () => {
        return (
            <>
                {options.map((element, index) => (
                    element.options?.length > 0 ?
                        <MultipleHeaderMenu
                            close={handleClose}
                            options={element.options}
                            rootComponent={
                                <Link key={index} onClick={(event) => event.preventDefault()} to='#' className={classes.navigationMenuItemLink} >
                                    <StyledMenuItem test={true}>
                                        <ListItemText primary={<Typography variant="caption" >{element.name || element.label}</Typography>} />
                                    </StyledMenuItem>
                                </Link>
                            }
                            prefix={element.id}
                            children={true}
                        />
                        :
                        <Link key={index} to={prefix ? prefix + element.id : element.id} className={classes.navigationMenuItemLink + ' ' + ((element.id === 1 || element.id === 2 || element.id === 5) ? '' : (children ? (element.id === selected || (selected === undefined && index === 0) ? classes.linkDisabled : "") : classes.linkDisabled))} onClick={(e) => handleClose(e, element, children)}>
                            <StyledMenuItem className={(element.id === 1 || element.id === 2 || element.id === 5) ? (element.id === selected ? classes.linkDisabled : '') : (children ? (element.id === selected || (selected === undefined && index === 0) ? classes.linkDisabled : "") : classes.linkDisabled)}>
                                <ListItemText primary={<Typography variant="caption" >{element.name || element.label}</Typography>} />
                            </StyledMenuItem>
                        </Link>
                ))}
                {/* {
                    prefix === '/institution/' ?
                        <>
                            <Divider style={{marginBottom: '0.7rem'}}/>
                            <Link key={7} to={prefix + 7} className={classes.navigationMenuItemLink} onClick={(e) => handleClose(e, {id: 7}, children)}>
                                <StyledMenuItem>
                                    <ListItemText primary={<Typography variant="caption" >{'Interinstitucional'}</Typography>} />
                                </StyledMenuItem>
                            </Link>
                        </>
                        :
                        null
                } */}
            </>
        )
    };

    return (
        <div>
            <div onClick={handleClick}>
                {rootComponent}
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginLeft: margin ? '1rem' : '0rem' }}
                type={children}
            >
                {renderOptions()}
            </StyledMenu>
        </div>
    )
}
