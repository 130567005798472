const institutionsOptions = {
    2: [
        { id: '/1', name: 'Casos ingresados' },
        { id: '/2', name: 'Salidas' },
        { id: '/3', name: 'Personas sentenciadas' },
        { id: '/4', name: 'Presupuesto' },
    ],
    5: [
        { id: '/1', name: 'Prisión preventiva' },
        { id: '/2', name: 'Egresos' },
        { id: '/3', name: 'Tasa de ocupación' },
        { id: '/4', name: 'Presupuesto' },
    ],
};

export default institutionsOptions;
