import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar } from "@material-ui/core";

//import logo4 from "assets/img/lasalleLogo.svg"; logo to be displayed
//import exampleLogo from "../assets/img/cienLogo.png";

import { Link } from "react-router-dom";
import AppContext from "state/AppContext.js";
import clsx from 'clsx';

import exampleLogo from "assets/img/Logo-justiciacriminal.svg";
import betaLogo from "assets/img/Logo-justiciacriminal.svg";
import indicadoresLogo from "assets/img/logo-indicadores-justiciacriminal.svg";

import styles from "assets/jss/headerstyle.js";
/* import PageIndicator from "./PageIndicator"; */
import MultipleHeaderMenu from "./MultipleHeaderMenu";
import HeaderMenuCopy from "./HeaderMenu";
import ApiURLs from "config/ApiURLs";
import axios from "axios";

const useStyles = makeStyles(styles);

export default function PrimaryHeaderCopy(props) {
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const retrieveInstitutions = () => {
        if (!appContext.state.isLoading)
            return appContext.state.institutions;
        else return [];
    }

    const url = window.location.host.split(':')[0];

    const logOut = (e) => {
        e.preventDefault();
        if (sessionStorage.getItem('userInfo') === null) return;
        const token = JSON.parse(sessionStorage.getItem('userInfo'));

        axios.post(`${ApiURLs.baseURL}api/close-session`, {}, {
            headers: { Authorization: `Bearer ${token.access_token}`, },
        }).then(function (response) {
            console.log(response);
            window.location.href = '/bienvenido';
        }).catch(error => { }).finally(function () { });
        sessionStorage.removeItem('userInfo');
        document.cookie = ApiURLs.cookie + "=" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    return (
        <AppBar position='static' className={classes.appBar}>
            {/* <PageIndicator /> */}
            <div className={classes.wrapper}>
                <Toolbar className={classes.toolBar} disableGutters>
                    <div className={classes.leftContent}>
                        <Link to="/index" className={classes.headerContainer} >
                            <img src={window.location.href.search('institution') === -1 ? betaLogo : indicadoresLogo} className={classes.headerLogo} alt={window.location.href.search('institution') === -1 ? 'Justicia Criminal' : "Indicadores de la Justicia Criminal"} />
                        </Link>
                    </div>
                    <div className={classes.rightContent}>
                        {/* <h3 className={classes.navLinks}>Índice de datos</h3> */}
                        {/* <Typography variant="h3" className={classes.navLinks}>Índice de datos</Typography>
                        <MenuIcon className={classes.navBarButton} /> */}
                        <div className={classes.rightContentElementsFirst} >
                            <div className={classes.navBarIcon}>
                                <Link to={{ pathname: "https://www.instagram.com/cien.guatemala/" }} target="_blank"><i class="fab fa-instagram fa-2x" /></Link>
                            </div>
                            <div className={classes.navBarIcon}>
                                <Link to={{ pathname: "https://www.facebook.com/cien.guatemala/" }} target="_blank"><i class="fab fa-facebook fa-2x" /></Link>
                            </div>
                            <div className={classes.navBarIcon}>
                                <Link to={{ pathname: "https://twitter.com/ciengt" }} target="_blank"><i class="fab fa-twitter fa-2x" /></Link>
                            </div>
                        </div>
                        <div className={classes.rightContentElementsFirst}>
                            <Link to='/'><div className={classes.navLink}><i class="fas fa-home" /></div></Link>
                            <Link to='/indicadores'><div className={clsx(classes.navLink, classes.mobileHidden)}>Indicadores</div></Link>
                            <Link to={{ pathname: "https://justiciacriminal.gt" }} target="_blank"><div className={clsx(classes.navLink, classes.mobileHidden)}>Flujograma</div></Link>
                            <Link to='/methodology'><div className={clsx(classes.navLink, classes.mobileHidden)}>Metodología</div></Link>
                            {
                                sessionStorage.getItem('userInfo') !== null
                                    ?
                                    <Link href={''} onClick={e => logOut(e)}>
                                        <div className={classes.navLink}>
                                            <i class="fas fa-sign-out-alt" style={{ marginRight: '0.3rem' }} />Cerrar sesión
                                        </div>
                                    </Link>
                                    : <a href={url === 'beta.justiciacriminal.gt' ? "https://beta.justiciacriminal.gt/login" : 'http://localhost:3001/login'} target="_self">
                                        <div className={classes.navLink}>
                                            <i class="fa fa-user" style={{ marginRight: '0.3rem' }} />Ingreso
                                        </div>
                                    </a>
                            }
                            <HeaderMenuCopy />
                        </div>
                    </div>
                </Toolbar>
            </div>
        </AppBar>
    );
}

// Código para menú recursivo
/* <MultipleHeaderMenu
    options={
        [
            { id: ('/flujograma/' + baseYear), name: 'Anual' },
            { id: '/flujograma-trimestre/' + baseYear + "/1", name: 'Trimestral' },
            {
                id: '/institution/',
                name: 'Eficiencia por institución',
                options: retrieveInstitutions()
            },
        ]
    }
    rootComponent={
        <Link onClick={(event) => event.preventDefault()} to='#'>
            <div className={clsx(classes.navLink, classes.mobileHidden)}>Flujograma</div>
        </Link>
    }
    children={false}
    close={() => { }}
/> */