import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Typography } from "@material-ui/core";

import BaseCard from "components/BaseCard.js";
import styles from "assets/jss/baseCardstyle.js";
import styles2 from "assets/jss/institutionScreenstyle.js";
import MultipleHeaderMenu from "./MultipleHeaderMenu";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function GeneralDescription(props) {
    const { type } = props;
    const classes = useStyles();
    const classes2 = useStyles2();

    const CasosIngresadosMP = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                Año: <b>2022</b>
                            </Typography>
                        </div>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                1. Casos ingresados: <b>393,006</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                2. Tasa de casos ingresados en PNC: <b>43%</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                3. Tasa de gestión* de casos: <b>89%</b>
                            </Typography>
                        </div>

                        <div className={classes2.footerContainer}>
                            <Typography variant className={classes2.longDescription}>
                                <em>
                                    * Un caso gestionado es el que ha sido ingresado a una fiscalía. Los casos no gestionados son depurados al considerarse que no constituyen delito. <br />
                                </em>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid>
        )
    }

    const SalidasMP = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                Año: <b>2022</b>
                            </Typography>
                        </div>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                4. Casos a los que se les dio salida: <b>804,072</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                5. Tasa de atención de casos: <b>229%</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                6. Tasa de casos archivados*:<b>23%</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                7. Tasa de acusaciones: <b>1.9%</b>
                            </Typography>
                        </div>

                        <div className={classes2.footerContainer}>
                            <Typography variant className={classes2.longDescription}>
                                <em>
                                    * Un caso puede ser archivado cuando no se puede individualizar al imputado o se declara su rebeldía. <br />
                                </em>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid>
        )
    }

    const PresupuestoOJ = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                Año: <b>2023</b>
                            </Typography>
                        </div>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                1. Presupuesto aprobado: <b>Q3,726.0 millones</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                2. Presupuesto vigente: <b>Q4,156.0 millones</b> <label style={{ fontSize: 'smaller' }}>(al 31 de julio de 2023)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                3. Presupuesto aprobado en proporción al nacional: <b>3.23%</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                4. Ejecución presupuestaria: <b>44.3%</b> <label style={{ fontSize: 'smaller' }}>(al 31 de julio de 2023)</label>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid>
        )
    }

    const PrisionPreventivaSP = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                1. Tasa de prisión preventiva: <b>40.5%</b> <label style={{ fontSize: 'smaller' }}>(al 31 de mayo de 2023)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                2. Egreso anual de personas de las prisiones: <b>18,856</b> <label style={{ fontSize: 'smaller' }}>(año 2022)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                3. Tasa de ocupación* de las prisiones: <b>352%</b> <label style={{ fontSize: 'smaller' }}>(al 31 de mayo de 2023)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                4. Reclusos por personal de custodia**: <b>16 reclusos</b> <label style={{ fontSize: 'smaller' }}>(al 31 de mayo de 2023)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                5. Reclusos por personal de rehabilitación: <b>230 reclusos</b> <label style={{ fontSize: 'smaller' }}>(al 31 de mayo de 2023)</label>
                            </Typography>
                        </div>

                        <div className={classes2.footerContainer}>
                            <Typography variant className={classes2.longDescription}>
                                <em>
                                    * Las prisiones se consideran hacinadas cuando el porcentaje de ocupación que sobrepasa el 120%. <br />
                                    ** Se toma en cuenta al total del personal de custodia dividido en dos turnos.
                                    <br />
                                </em>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid >
        )
    }

    const PresupuestoSP = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                1. Presupuesto aprobado: <b>Q647.2 millones</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                2. Presupuesto vigente: <b>Q640.3 millones</b> <label style={{ fontSize: 'smaller' }}>(al 31 de julio de 2023)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                3. Presupuesto aprobado en proporción al nacional: <b>0.56%</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                4. Ejecución presupuestaria: <b>46.4%</b> <label style={{ fontSize: 'smaller' }}>(al 31 de julio de 2023)</label>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid>
        )
    }

    const DEICDICRI = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '0.8rem' }}>Año:</div>
                                    <MultipleHeaderMenu
                                        options={[{ id: 7, name: '2022' }]}
                                        prefix=""
                                        rootComponent={
                                            <Link onClick={(event) => event.preventDefault()} to="#">
                                                <b style={{ marginRight: '0.3rem' }}>2022</b>
                                                <i className='fa fa-caret-down' />
                                            </Link>
                                        }
                                        margin={true}
                                        children={true}
                                        selected={7}
                                        close={() => { }}
                                    />
                                </div>
                            </Typography>
                        </div>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="h2" className={classes2.typeContainer} style={{ color: '#575E5F', textDecoration: 'underline' }}>
                                DEIC <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                1. Investigaciones requeridas a DEIC: <b>111,425</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                2. Investigaciones realizadas por DEIC:  <b>61,856</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer} style={{ marginBottom: '1rem' }}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                3. Investigaciones pendientes de diligenciar por DEIC: <b>49,569</b>
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="h2" className={classes2.typeContainer} style={{ color: '#575E5F', textDecoration: 'underline' }}>
                                DICRI <br />
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                1. Investigaciones requeridas a DICRI: <b>57,608</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                2. Investigaciones realizadas por DICRI:  <b>31,128</b>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                3. Investigaciones pendientes de diligenciar por DICRI: <b>26,480</b>
                            </Typography>
                        </div>

                        <div className={classes2.footerContainer}>
                            <Typography variant className={classes2.longDescription}>
                                <em>
                                    * DEIC son las siglas de la División Especializada en Investigación Criminal de la Policía Nacional Civil. <br />
                                    ** DICRI son las siglas de la Dirección de Investigaciones Criminalísticas del Ministerio Público.
                                    <br />
                                </em>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid>
        )
    }

    const PPTOII = () => {
        return (
            <Grid xs={12} sm={12} md={12}>
                <BaseCard>
                    <div className={classes.container} style={{ border: 'none', paddingTop: '.5rem', paddingBottom: '1rem' }}>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '0.8rem' }}>Año:</div>
                                    <MultipleHeaderMenu
                                        options={[{ id: 7, name: '2022' }]}
                                        prefix=""
                                        rootComponent={
                                            <Link onClick={(event) => event.preventDefault()} to="#">
                                                <b style={{ marginRight: '0.3rem' }}>2022</b>
                                                <i className='fa fa-caret-down' />
                                            </Link>
                                        }
                                        margin={true}
                                        children={true}
                                        selected={7}
                                        close={() => { }}
                                    />
                                </div>
                            </Typography>
                        </div>
                        <div className={classes2.typeParent}>
                            <Typography variant="h1" className={classes2.typeContainer} style={{ color: '#575E5F' }}>
                                Indicadores <br />
                            </Typography>
                        </div>

                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                7. Prespuesto de Gastos* por Institución: <b>11,484 millones en total</b> <label style={{ fontSize: 'smaller' }}>(año 2022)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                8. Gastos de Funcionamiento* por Institución: <b>11,205 millones en total</b> <label style={{ fontSize: 'smaller' }}>(año 2022)</label>
                            </Typography>
                        </div>
                        <div className={classes2.subtitleContainer} style={{ marginBottom: '1rem' }}>
                            <Typography variant="body1" className={classes2.longDescription}>
                                9. Presupuesto de Ingresos* por fuente de Financiamiento: <b>más del 90% financiado por Administración Central</b> <label style={{ fontSize: 'smaller' }}>(año 2022)</label>
                            </Typography>
                        </div>

                        <div className={classes2.footerContainer}>
                            <Typography variant className={classes2.longDescription}>
                                <em>
                                    *  Se analiza el presupuesto devengado por las instituciones. <br />
                                </em>
                            </Typography>
                        </div>
                    </div>
                </BaseCard>
            </Grid>
        )
    }

    switch (type) {
        case 'DBOARD-MP001':
            return <CasosIngresadosMP />;
        case 'DBOARD-MP002':
            return <SalidasMP />;
        case 'DBOARD-OJ001':
            return <PresupuestoOJ />;
        case 'DBOARD-SP001':
            return <PrisionPreventivaSP />;
        case 'DBOARD-SP004':
            return <PresupuestoSP />;
        case 'DBOARD-II001':
            return <DEICDICRI />;
        case 'DBOARD-II003':
            return <PPTOII />;
        default:
            return null;
    }
}